/* ------------------------------------------------------------ */
/** NavBar Styling                                             **/
/* ------------------------------------------------------------ */

.Navbar-SignOut {
    margin-right: 10px;
    cursor: pointer;
}

.Navbar-SignOut:hover~.Navbar-SignOut-Dropdown {
    visibility: visible;
}

.Navbar-SignOut-Dropdown {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 70px;
    right: 0px;
    background-color: white;
    color: black;
    border: none;
    border-radius: 1px;
    box-shadow: 5px 10px 35px 3px rgba(81, 81, 81, 0.35);
    visibility: hidden;
    transition-duration: 0.3s;
}

.Navbar-SignOut-Dropdown:hover {
    visibility: visible;
}

.Navbar-SignOut-Dropdown-Link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 10px 25px;
    font-size: 1rem;
    border-bottom: 1px solid var(--lightgrey);
    width: 100%;
    color: black;
    text-decoration: none;
}

.Navbar-SignOut-Dropdown-Link:hover {
    background-color: rgb(241, 241, 241);
    cursor: pointer;
    color: black;
}