.DisclaimerModal-Container{
    display: grid;
    border-radius: 5px;
    position: absolute;
    width: 50vw;
    min-width: 300px;
    top: 17.5%;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    z-index: 100;
    gap: 10px;
    border: 1px solid #141C25;
    background-color: #141C25;
    color: white;
}

@media (width <= 450px) {

    .DisclaimerModal-Container{
        display: grid;
        border-radius: 5px;
        position: absolute;
        width: 50vw;
        min-width: 325px;
        top: 17.5%;
        background-color: white;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
        z-index: 100;
        gap: 10px;
        border: 1px solid #141C25;
        background-color: #141C25;
        color: white;
        max-height: 500px;
        overflow-y: auto;
    }
    
}

.DisclaimerModal-Page{
    display: grid;
    grid-template-rows: 1fr 50px;
    grid-template-areas: 
        'Content'
        'Buttons';
}

.DisclaimerModal-Content{
    grid-area: Content;
    line-height: 1.7;
}

.DisclaimerModal-Content h4{
    border-bottom: 2px solid #d1d1d1;
    margin: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.DisclaimerModal-Item {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    background-color: #141C25;
    border-radius: 5px;
    padding: 10px;
    color: white;
    font-weight: 400;
}
.DisclaimerModal-Item img {
    margin-right: 10px;
}

.DisclaimerModal-Rules {
    display: flex;
    flex-direction: column;
    justify-content: left;
    border-radius: 5px;
    padding: 10px;
    color: white;
    font-weight: 400;
}
.DisclaimerModal-Rules div img {
    margin-right: 10px;
}
.DisclaimerModal-Rules div:last-child{
    padding: 10px;
}

.DisclaimerModal-Button-Single{
    grid-area: Buttons;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    padding: 10px;
}

.DisclaimerModal-Primary-Button{
    border: 2.5px solid white;
    background-color: white;
    color: #141C25;;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px 0px 5px 0px;
    font-weight: 600;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
    font-size: var(--body);
  }