/* ------------------------------------------------------------ */
/** App Styling                                                **/
 /* ------------------------------------------------------------ */

/* Ensures if you change the box-sizing of an element, everything inside the element will inherit the box-sizing of that element */
*,
*:before,
*:after {
    box-sizing: inherit;
}

.App-Container{
    display: grid;
    grid-template-rows: 70px calc(100vh - 124.5px) 40px;
    grid-template-areas:
        'App-Container-Nav'
        'App-Container-Body'
        'App-Container-Footer';

}

@media (max-width: 1025px) {
    .App-Container {
        grid-template-rows: 60px calc(100vh - 137px) 50px;
    }
}

.App-Container-Nav{
    grid-area: App-Container-Nav;
    width: 100%;
}

.App-Container-Body{
    grid-area: App-Container-Body;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.App-Container-Footer {
    grid-area: App-Container-Footer;
}
