.Chat-Section-Container {
    display: grid;
    grid-template-columns: 65vw 1fr;
    background: #FFFFFF;
    height: calc(100vh - 130px);
    /* Allows the left and right panes to fill the page */
    margin: 0px;
}

/* =========================== */
/*        LEFT SIDE            */
/* =========================== */

.Chat-Section-Left {
    border-right: 2px solid #d8d8d8bb;
    padding: 2%;
    display: grid;
    grid-template-rows: 1fr 130px;
    height: calc(100vh - 130px);
    gap: 15px;
}

@media (max-width: 1024px) {
    .Chat-Section-Left {
        height: calc(100vh - 75px);
    }
}

/* Question asked by the user */
.Chat-Question {
    margin: 0px 10px 10px 10px;
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
    background-color: #F5F5F5;
    white-space: pre-wrap;
}

/* Answer asked by the user */
.Chat-Answer {
    margin: 0px 10px 10px 10px;
    padding: 10px;
    border-radius: 5px;
    color: black;
    background-color: #E9EBED;
}

/* Chat Input */
.Chat-Input {
    grid-area: question;
    height: 100%;
    width: 100%;
    padding: 10px;
    border: 0.5px solid #daddd7;
    color: #3A3A3A;
    text-align: left;
    border-radius: 10px;
    line-height: 2.0rem;
    white-space: pre-wrap;
    resize: none;
}

@media (max-width: 1024px) {
    .Chat-Input {
        height: 4rem;
    }
}

.Chat-Input:focus {
    outline: none;
}

.Chat-Input:disabled {
    border: 0.5px solid #daddd752;
    background-color: white;
}

/* =========================== */
/*       Right SIDE            */
/* =========================== */

.Chat-Section-Right {
    display: grid;
    grid-template-rows: 1fr 50px;
    padding: 20px;
    margin-bottom: 10px;
    height: calc(100vh - 130px);
}

@media (max-width: 1024px) {
    .Chat-Section-Right {
        height: calc(100vh - 75px);
    }
}

.Chat-Section-Right-Threads {
    overflow-x: auto;
}

/* Media query for mobile and tablet devices */
@media (max-width: 1024px) {

    /* Hide the right side */
    .Chat-Section-Right {
        display: none;
    }

    /* Adjust left side to take full width */
    .Chat-Section-Container {
        grid-template-columns: 1fr; /* Make the left side fill the width */
        height: calc(100vh - 130px); /* Allows the left and right panes to fill the page */
        margin: 0px;
        overflow-y: hidden;
    }
}